import uuidv1 from "uuid/v1";

import { actiontypes } from "../constants/fields";
import { getHighestFieldOrder, getFieldByID, getFieldsByTableID, getTableIdByFieldId } from "../selectors/fields";

export function addField(id) {
	return async function(dispatch, getState) {
        dispatch({
            type: actiontypes.ADD_FIELD,
            payload: {
                id: uuidv1(),
                tableId: id,
                type: 0,
                name: "",
                order: getHighestFieldOrder(getState().fields, id) + 1
            }
        });
	};
}
export function deleteField(id) {
	return async function(dispatch, getState) {
        dispatch({
            type: actiontypes.DELETE_FIELD,
            payload: {
                id: id,
                tableId: getTableIdByFieldId(getState().fields, id)
            }
        });
	};
}
export function deleteFieldsByTableId(id) {
	return async function(dispatch, getState) {
        dispatch({
            type: actiontypes.DELETE_FIELDS,
            payload: {
                id: id
            }
        });
	};
}

// TODO: combine these update functions?
export function updateFieldName(id, name) {
	return async function(dispatch, getState) {
        dispatch({
            type: actiontypes.UPDATE_NAME,
            payload: {
                id: id,
                name: name
            }
        });
	};
}
export function updateFieldType(id, type) {
	return async function(dispatch, getState) {
        dispatch({
            type: actiontypes.UPDATE_TYPE,
            payload: {
                id: id,
                type: type
            }
        });
	};
}

export function updateFieldOrder(id, dir) {
	return async function(dispatch, getState) {

        const currentField = getFieldByID(getState().fields, id);
        const currentOrder = currentField.order;
        const allFields = getFieldsByTableID(getState().fields, currentField.tableId);
        const newOrder = (dir === "up") ? currentOrder-1 : currentOrder +1;

        // get other field (the one that's in the order the current field wants to move to) an calc a new order for that field
        const otherField = allFields.filter( (f) => f.order === newOrder)[0];
        const newOtherFieldOrder = (dir === "up") ? otherField.order+1 : otherField.order-1;

        dispatch({
            type: actiontypes.UPDATE_ORDER,
            payload: {
                currentField_Id: id,
                currentField_NewOrder: newOrder,
                otherField_Id: otherField.id,
                otherField_NewOrder: newOtherFieldOrder
            }
        });
	};
}
