import { actiontypes } from "../constants/modal";

const initialState = {
    modalType: "BasicModal",
    contentType: null,
    props: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actiontypes.SHOW_MODAL: {
            return {
                ...state,
                contentType: action.payload.contentType,
                props: action.payload.props
            };
        }
        case actiontypes.HIDE_MODAL: {
			return initialState;
        }
		default:
            return state;
    }
};
