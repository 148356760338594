import React, { Component , Fragment} from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Container, Row, Col, Button, ButtonGroup, Form, Label, Input, Table } from 'reactstrap';

// Redux stuff
import * as fieldActions from 'redux/actions/fields';
import * as tableActions from 'redux/actions/tables';
import * as modalActions from 'redux/actions/modal';
import { getFieldsByTableID } from "redux/selectors/fields";

// config
import config, { getFieldOptions } from "config/config";
const fieldTypes = config.fields.fieldTypes;


class DataJen extends Component {
    addField = (id) => {
        this.props.actions.fields.addField(id);
    }
    deleteField = (id) => {
        this.props.actions.fields.deleteField(id);
    }
    updateFieldName = (id, e) => {
        this.props.actions.fields.updateFieldName(id, e.target.value);
    }
    updateFieldType = (id, e) => {
        this.props.actions.fields.updateFieldType(id, parseInt(e.target.value));
    }
    updateFieldOrder = (id, dir) => {
        this.props.actions.fields.updateFieldOrder(id, dir);
    }
    addTable = () => {
        this.props.actions.tables.addTable();
    }
    deleteTable = (id) => {
        this.props.actions.tables.deleteTable(id).then( () => {
            this.props.actions.fields.deleteFieldsByTableId(id);
        });
    }
    updateTableRows = (id, e) => {
        this.props.actions.tables.updateTableRows(id, e.target.value);
    }
    updateTableName = (id, e) => {
        this.props.actions.tables.updateTableName(id, e.target.value);
    }
    viewStore = () => {
        this.props.actions.modals.openModal(
            "datapreview",
            {
                data: {
                    tables: this.props.tables,
                    fields: this.props.fields,
                    fieldTypes: fieldTypes
                }
            }
        );
    }
    generateData = () => {
        const data = this.props.tables.map( (table) => {
            return {
                ...table,
                fields: getFieldsByTableID(this.props.fields, table.id)
            };
        })
        console.log(data);
    }
	render() {
		return (
            <Fragment>
                <Container fluid style={{marginBottom: 20, marginTop: 20}}>
                    <Row>
                        <Col>
                            <ButtonGroup size="sm" style={{marginRight: 20}}>
                                <Button onClick={this.viewStore}>View store</Button>
                                <Button onClick={this.generateData}>Generate data</Button>
                            </ButtonGroup>
                            <Button color="primary" size="sm" onClick={this.addTable}>add table</Button>
                        </Col>
                    </Row>
                </Container>
                {
                    this.props.tables.map( (table,i) => {

                        const fields = getFieldsByTableID(this.props.fields, table.id);
                        return(
                            <Container fluid key={`container_field_${table.id}`} style={{marginBottom: 20}}>
                                <Form className="c_table-block">
                                    <Row>
                                        <Col sm={1}>Table #{i+1}</Col>
                                        <Col sm={4}>
                                            <Input type="text" bsSize="sm" placeholder="Table Name *" value={table.name} onChange={ (e) =>
                                                this.updateTableName(table.id, e)
                                            } />
                                        </Col>
                                        <Label sm={1}># Rows *:</Label>
                                        <Col sm={2}>
                                            <Input type="number" bsSize="sm" placeholder="Amount of rows *" value={table.rows} onChange={ (e) =>
                                                this.updateTableRows(table.id, e)
                                            } />
                                        </Col>
                                        <Col sm={4}>
                                            <ButtonGroup size="sm">
                                                <Button color="warning" onClick={() => {
                                                    this.addField(table.id)
                                                }}>add field</Button>
                                                {this.props.tables.length > 1 &&
                                                    <Button color="primary" onClick={ () => {
                                                        this.deleteTable(table.id)
                                                    }}>delete table</Button>
                                                }
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                    {
                                        fields.length > 0 &&
                                        <Row>
                                            <Col>
                                                <FieldTable
                                                    table={table}
                                                    fields={fields}
                                                    updateFieldName={this.updateFieldName}
                                                    updateFieldType={this.updateFieldType}
                                                    deleteField={this.deleteField}
                                                    updateFieldOrder={this.updateFieldOrder}
                                                />
                                            </Col>
                                        </Row>
                                    }
                                </Form>
                            </Container>
                        );
                    })
                }

            </Fragment>
		);
	}
}
const mapStateToProps = state => {
	return {
        fields: state.fields,
        tables: state.tables
	};
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            fields: bindActionCreators(fieldActions, dispatch),
            tables: bindActionCreators(tableActions, dispatch),
            modals: bindActionCreators(modalActions, dispatch)
        }
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(DataJen);



const FieldTable = (props) => {
    return(
        <Table>
            <tbody>
                <tr>
                    <th style={{borderTop: 'none'}}><b>NAME:</b></th>
                    <th style={{borderTop: 'none'}}><b>TYPE:</b></th>
                    <td style={{borderTop: 'none'}}></td>
                </tr>
                {props.fields.map( (field, i) => {
                    return(
                        <Fragment key={field.id}>
                            <tr>
                                <td>
                                    <Input type="text" bsSize="sm" placeholder="Field Name *" value={field.name} onChange={ (e) =>
                                        props.updateFieldName(field.id, e)
                                    } />
                                </td>
                                <td>
                                    <Input type="select" bsSize="sm" value={field.type} onChange={ (e) =>
                                        props.updateFieldType(field.id, e)
                                    }>
                                        {fieldTypes.map((t, i) => <option key={t.id} value={t.id}>{t.name}</option> )}
                                    </Input>
                                </td>
                                <td>
                                    <ButtonGroup size="sm">
                                        <Button color="warning" onClick={ () => {
                                            props.deleteField(field.id)
                                        }}>x</Button>
                                        {
                                            // hide up arrow on first field
                                            i!== 0 &&
                                            <Button color="warning" onClick={ () => {
                                                props.updateFieldOrder(field.id, 'up')
                                            }}>&uarr;</Button>
                                        }
                                        {
                                            // hide down arrow on last field
                                            i !== (props.fields.length-1) &&
                                            <Button color="warning" onClick={ () => {
                                                props.updateFieldOrder(field.id, 'down')
                                            }}>&darr;</Button>
                                        }
                                    </ButtonGroup>
                                </td>
                            </tr>
                            {
                                fieldTypes[field.type].options.length > 0 &&
                                <tr>
                                    <td colSpan={2} style={{borderTop: 'none'}}>
                                        <FieldOptions
                                            field={field}
                                            options={getFieldOptions(fieldTypes[field.type].options)}
                                        />
                                    </td>
                                </tr>
                            }
                        </Fragment>
                    );
                })}

            </tbody>
        </Table>
    );
}


const FieldOptions = (props) => {
    return(
        <Row>
            {
                props.options.map( (o) => {
                    return(
                        <Col key={`optioncolumn-${props.field.id}-${o.id}`} md={4} lg={3}>
                            <FieldOption option={o}/>
                        </Col>
                    );
                })
            }
        </Row>
    );
}


const FieldOption = (props) => {
    switch(props.option.type) {
        case "number":
            return(
                <Fragment>
                    <Input type="number" bsSize="sm" placeholder={props.option.name} />
                </Fragment>
            )
        default:
            return <pre style={{whiteSpace: 'initial'}}>{JSON.stringify(props.option)}</pre>
    }

}
