import "react-app-polyfill/ie11"; //if ie9 it supports ie9-ie11

import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./helpers/serviceWorker";

import App from "./App";

const rootDiv = document.getElementById("root");
ReactDOM.render(<App />, rootDiv)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
