const config = Object.freeze({
    fields: {
        // These are the types of available fields a user can choose from to use..
        // TODO: where are we going to define these fieldTypes? Backend?
        // TODO: how are we going to extend these?
        // REVIEW: is "options" descriptive enough?
        // REVIEW: should "name" be "label"? Because that's what its used for in the frontend...
        fieldTypes: [
            {
                id: 0,
                type: "boolean",
                name: "Boolean",
                // settings: {
                //     someSetting: "someValue"
                // },
                options: []
            },
            {
                id: 1,
                type: "integer",
                name: "Integer",
                // settings: {
                //     someSetting: "someValue"
                // },
                options: [0]
            },
            {
                id: 2,
                type: "varchar",
                name: "VarChar",
                // settings: {
                //     someSetting: "someValue"
                // },
                options: [0,1]
            }
        ],

        // Each fieldType can have one or more of these extra fieldOptions.
        // TODO: validationRules
        fieldOptions: [
            {
                id: 0,
                type: "number",
                name: "Blank percentage",
                defaultValue: 0,
                validationRules: []
            },
            {
                id: 1,
                type: "checkbox",
                name: "allow NULL",
                defaultValue: false,
                validationRules: []
            }
        ]
    }
});
export default config;



export const getFieldOptions = (idArray = []) => {
    return idArray.map( (id) => {
        return config.fields.fieldOptions[id];
    });
}
