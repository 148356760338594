import React, { Component } from 'react';
import * as ReactModal from 'react-modal';
import { Button } from 'reactstrap';

const CNAME = "c_basic-modal"
ReactModal.setAppElement('#root');

class BasicModal extends Component {

    onAfterOpen = () => {
        // props get passed in by dispatching the "openModal" action
        if(this.props.afterOpen) {
            this.props.afterOpen();
        }
    }
    onRequestClose = () => {
        // props get passed in by dispatching the "openModal" action
        this.props.beforeClose && this.props.beforeClose();

        this.props.closeModal().then( () => {
            // props get passed in by dispatching the "openModal" action
            this.props.afterClose && this.props.afterClose();
        });
    }
    render() {
        // pass extra props to all rendered children
        const children = React.Children.map(this.props.children, child => {
            return React.cloneElement(child, {
                CNAME: CNAME
            });
        });
        return(
            <ReactModal
                isOpen={true}
                onAfterOpen={this.afterOpen}
                onRequestClose={this.onRequestClose}
                contentLabel={this.props.data.title}
                portalClassName={CNAME}
                overlayClassName={`${CNAME}__overlay`}
                className={`${CNAME}__content`}
                // bodyOpenClassName="ReactModal__Body--open"
                // htmlOpenClassName="ReactModal__Html--open"
                shouldFocusAfterRender={true}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                shouldReturnFocusAfterClose={false}
                role="dialog"
            >
                <div className={`${CNAME}__inner-content`}>
                    <Button onClick={this.onRequestClose}>close</Button>
                    {children}
                </div>
            </ReactModal>
        );
    }
}
export default BasicModal;
