import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


import * as modalActions from 'redux/actions/modal';
import BasicModal from "./BasicModal/BasicModal"
import DatapreviewModalContent from "./ModalContent/DatapreviewModalContent";

// bind all modalTypes to the correct modalComponents here!
const MODAL_MODALTYPES = {
    "BasicModal": BasicModal
};
const MODAL_CONTENTTYPES = {
    "datapreview": DatapreviewModalContent
};


class Modal extends PureComponent {
    render() {
        // if no type: snow no modal
        if (!this.props.modal.contentType) {
            return null;
        }
        // get correct modalType and modalContent based on type of modal provided by dispatched action
        const ModalType = MODAL_MODALTYPES[this.props.modal.modalType];
        const ModalContent = MODAL_CONTENTTYPES[this.props.modal.contentType];
        return (
            <ModalType {...this.props.modal.props} closeModal={this.props.actions.modal.closeModal}>
                <ModalContent {...this.props.modal.props}  />
            </ModalType>
        );
    }
};

const mapStateToProps = (state) => {
	return {
        modal: state.modal
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            modal: bindActionCreators(modalActions, dispatch),
        }
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Modal);
