import { actiontypes } from "../constants/fields";

const initialState = [];

export default (state = initialState, action) => {
    switch (action.type) {
        case actiontypes.ADD_FIELD: {
            return state.concat(action.payload);
        }
        case actiontypes.DELETE_FIELD: {
            let index = 0;
            return [
                ...state
                    .filter( f => (f.id !== action.payload.id))
                    .map( (f, i) => {
                        // this updates the order of all the fields for this table!
                        if(f.tableId === action.payload.tableId) {
                            index++;
                            return {
                                ...f,
                                order: index
                            };
                        }
                        return f;
                    })
            ];
        }
        case actiontypes.DELETE_FIELDS: {
            return state.filter( f => (f.tableId !== action.payload.id));
        }
        case actiontypes.UPDATE_NAME: {
            return state.map((f, i)=>{
                if(f.id === action.payload.id) {
                    return {
                        ...f,
                        name: action.payload.name
                    }
                }
                return f;
            });
        }
        case actiontypes.UPDATE_TYPE: {
            return state.map((f, i)=>{
                if(f.id === action.payload.id) {
                    return {
                        ...f,
                        type: action.payload.type
                    }
                }
                return f;
            });
        }
        case actiontypes.UPDATE_ORDER: {
            return state.map( (f) => {
                // update current field order
                if( f.id === action.payload.currentField_Id) {
                    return {
                        ...f,
                        order: action.payload.currentField_NewOrder
                    }
                }
                // update other field order
                if( f.id === action.payload.otherField_Id) {
                    return {
                        ...f,
                        order: action.payload.otherField_NewOrder
                    }
                }
                return f;
            });
        }

		default:
            return state;
    }
};
