import { actiontypes } from "../constants/modal";

export function openModal(contentType, props) {
	return async function(dispatch, getState) {
        dispatch({
            type: actiontypes.SHOW_MODAL,
            payload: {
                // modalType: "BasicModal", for now: this is set as default in the reducers initialState.. customizable if needed.
                contentType: contentType,
                props: props
            }
        });
	};
}
export function closeModal() {
	return async function(dispatch, getState) {
        dispatch({
            type: actiontypes.HIDE_MODAL
        });
	};
}
