import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from 'redux-thunk';
import tables from "../reducers/tables";
import fields from "../reducers/fields";
import modal from "../reducers/modal";

const localStorageKey = "DATAJEN";


// get initState from localStorage
let initState = {}
const persistedState = localStorage.getItem(localStorageKey);
if (persistedState) {
    initState = JSON.parse(persistedState)
}

// init the store with the initState
const store = createStore(
    combineReducers({
        tables,
        fields,
        modal
    }),
    initState,
    applyMiddleware(thunk)
);
store.subscribe( () => {

    // update localStorage!
    localStorage.setItem(localStorageKey, JSON.stringify(store.getState()));

    // show debug info
    (process.env.REACT_APP_DEBUG && process.env.REACT_APP_DEBUG === "true") && console.log('Updated Redux Store: ', store.getState());
});

export default store;
