export const getFieldsByTableID = (data, id) => {
    if(!id) {
        return data;
    }
    return data
        .filter( (el, i) => {
            return el.tableId === id
        })
        .sort((a, b) => a.order - b.order);
}
export const getFieldByID = (data, id) => {
    if(!id) {
        return false;
    }
    return data.filter( (el, i) => {
        return el.id === id
    })[0];
}
export const getTableIdByFieldId = (data, id) => {
    return data.filter( (el, i) => {
        return el.id === id
    })[0].tableId;
}
export const getHighestFieldOrder = (data, id) => {
    const fields = getFieldsByTableID(data, id);
    if(fields.length === 0) {
        return 0;
    }
    let h = 0;
    fields.map( (f) => {
        h = (f.order > h) ? f.order : h;
        return false;
    });
    return h;
}
