import uuidv1 from "uuid/v1";

import { actiontypes } from "../constants/tables";

export function addTable() {
	return async function(dispatch, getState) {
        dispatch({
            type: actiontypes.ADD_TABLE,
            payload: {
                id: uuidv1(),
                name: ""
            }
        });
	};
}
export function deleteTable(id) {
	return async function(dispatch, getState) {
        dispatch({
            type: actiontypes.DELETE_TABLE,
            payload: {
                id: id
            }
        });
	};
}
export function updateTableRows(id, rows) {
	return async function(dispatch, getState) {
        dispatch({
            type: actiontypes.UPDATE_TABLE_ROWS,
            payload: {
                id: id,
                rows: rows
            }
        });
	};
}

export function updateTableName(id, name) {
	return async function(dispatch, getState) {
        dispatch({
            type: actiontypes.UPDATE_TABLE_NAME,
            payload: {
                id: id,
                name: name
            }
        });
	};
}
