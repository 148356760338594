import DataJen from "../containers/DataJen/DataJen";
import DefaultPage from "../containers/DefaultPage/DefaultPage";

const routes = [
	{
		path: "/",
		exact: true,
        Component: DataJen,
        id: "route-datajen",
        // name: "datajen",
        label: "DataJen"
    },
    {
		path: "/about/",
		exact: true,
        Component: DefaultPage,
        id: "route-about",
        // name: "about",
        label: "About us",
        pageData: "about"
	},
    {
		path: "/contact/",
		exact: true,
        Component: DefaultPage,
        id: "route-contact",
        // name: "contact",
        label: "Contact",
        pageData: "contact"
	}
];

export default routes;
