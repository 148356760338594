import React, { PureComponent , Fragment} from "react";
import { Container, Row, Col, Jumbotron } from 'reactstrap';

class DefaultPage extends PureComponent {
	render() {
        const d = pageContent[this.props.pageData];
		return (
            <Fragment>
                <Jumbotron>
                    <h1 className="display-3">{d.jumbotron.title}</h1>
                    <p className="lead">{d.jumbotron.subTitle}</p>
                </Jumbotron>
                <Container style={{marginBottom: 20, marginTop: 20}}>
                    <Row>
                        <Col md={12} lg={8}>{d.content !== "" && d.content}</Col>
                    </Row>
                </Container>
            </Fragment>
		);
	}
}

export default DefaultPage;




const defaultEmptyContent = {
    jumbotron: {
        title: "",
        subTitle: ""
    },
    content: ""
}

const loremIpsum = (
    <Fragment>
        <p>Lorem ipsum dolor sit amet,consetetur sadipscing elitr,  sed diam voluptua.  sed diam voluptua.</p>
        <p>Lorem ipsum dolor sit amet,sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,  At vero eos et accusam et justo duo dolores et ea rebum.  Lorem ipsum dolor sit amet,</p>
        <p>Lorem ipsum dolor sit amet,At vero eos et accusam et justo duo dolores et ea rebum.  consetetur sadipscing elitr,  Lorem ipsum dolor sit amet,</p>
    </Fragment>
);

const pageContent = {
    about: {
        ...defaultEmptyContent,
        jumbotron: {
            title: "About",
            subTitle: "What makes us tick?"
        },
        content: loremIpsum

    },
    contact : {
        ...defaultEmptyContent,
        jumbotron: {
            title: "Contact",
            subTitle: "Feel free to contact us!"
        },
        content: (
            <Fragment>
                <p>
                    <b>Rik Jacobs</b><br />
                    <a href="mailto:rikjacobs1390@hotmail.com">rikjacobs1390@hotmail.com</a><br/>
                    <a href="tel:0622053042">0622053042</a><br/>
                </p><br/><br/>
                <p>
                    <b>Gijs Hendrikx</b><br />
                    <a href="mailto:dikzak@hotmail.com">dikzak@hotmail.com</a><br/>
                    <a href="tel:0600000000">0600000000</a><br/>
                </p>
            </Fragment>
        )
    }
}
