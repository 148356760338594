import React, { Component } from "react";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";

import store from "redux/store/store.js";

import routes from "routes/routes";
import { switchRoutes } from "routes/SwitchRoutes";
import { history } from "routes/history";

import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import Modal from "components/Modal/Modal";

import 'bootstrap/dist/css/bootstrap.min.css';
import "styles/styles.scss";

class App extends Component {
    render() {
		return (
			<Provider store={store}>
				<Router history={history}>
					<div className="wrapper">
						<Header />
						<main>{switchRoutes(routes)}</main>
						<Footer />
						<Modal />
					</div>
				</Router>
			</Provider>
		);
	}
}

export default App;
