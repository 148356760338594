import React, { Component } from "react";

class DatapreviewModalContent extends Component {
    render() {
        return(
            <div>
                <b>Tables:</b>
                <pre className={`${this.props.CNAME}__data`}>{JSON.stringify(this.props.data.tables)}</pre>
                <b>Fields:</b>
                <pre className={`${this.props.CNAME}__data`}>{JSON.stringify(this.props.data.fields)}</pre>
                <b>FieldTypes:</b>
                <pre className={`${this.props.CNAME}__data`}>{JSON.stringify(this.props.data.fieldTypes)}</pre>
            </div>
        );
    }
}
export default DatapreviewModalContent;
