import uuidv1 from "uuid/v1";

import { actiontypes } from "../constants/tables";

const stateDefaults = {
    language: "EN",
    rows: 1000
}
const initialState = [
    {
        id: uuidv1(),
        name: "",
        ...stateDefaults
    }
];

export default (state = initialState, action) => {
    switch (action.type) {
        case actiontypes.ADD_TABLE: {
			return state.concat({
                ...action.payload,
                ...stateDefaults
            });
        }
        case actiontypes.DELETE_TABLE: {
			return state.filter( (table) => {
                return table.id !== action.payload.id;
            });
        }
        case actiontypes.UPDATE_TABLE_ROWS: {
            return state.map((t, i)=>{
                if(t.id === action.payload.id) {
                    return {
                        ...t,
                        rows: action.payload.rows
                    }
                }
                return t;
            });
        }
        case actiontypes.UPDATE_TABLE_NAME: {
            return state.map((t, i)=>{
                if(t.id === action.payload.id) {
                    return {
                        ...t,
                        name: action.payload.name
                    }
                }
                return t;
            });
        }
		default:
            return state;
    }
};
