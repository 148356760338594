import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

export const switchRoutes = routes => {
	return (
		<Switch>
			{routes.map(({ path, Component, exact, pageData }, key) => (
				<Route
					key={key}
					exact={exact}
					path={path}
					render={({ match }) => <Component match={match} pageData={pageData} />}
				/>
			))}
			<Redirect to="/" />
		</Switch>
	);
};
