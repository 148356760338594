import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import routes from "routes/routes";

const CNAME = "c_header";

class Header extends Component {
	render() {
		return (
			<header className={`${CNAME}`}>
                <NavLink exact to={'/'} className={`${CNAME}__logo`}>DataJen <span className={`${CNAME}__logo-icon`}>&infin;</span></NavLink>
                <nav className={`${CNAME}__nav`}>
                    {
                        routes.map( (r, i) => (
                            <NavLink
                                exact
                                key={`naviagtion-item-${r.id}`}
                                to={r.path}
                                activeClassName="is-active"
                                className={`${CNAME}__nav-item`}
                            >
                                {r.label}
                            </NavLink>
                        ))
                    }
                </nav>
			</header>
		);
	}
}

export default Header;
